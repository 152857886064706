<template>
  <ClientOnly>
    <UInput
      icon="i-heroicons-magnifying-glass-20-solid"
      size="sm"
      class="w-40 hidden md:block"
      :ui="{
        base:
          'cursor-pointer focus:!ring-1 focus:!ring-gray-300  focus:dark:!ring-gray-700 opacity-75',
      }"
      color="white"
      :placeholder="LL.search()"
      @click="openSearch()"
    >
      <template #trailing>
        <div class="flex items-center gap-0.5">
          <UKbd>{{ metaSymbol }}</UKbd>
          <UKbd>K</UKbd>
        </div>
      </template>
    </UInput>
    <UModal v-model="isOpenSearch">
      <UCommandPalette
        ref="commandPaletteRef"
        class="mt-2"
        :empty-state="{
          label: LL.no_results(),
          queryLabel: LL.no_results(),
        }"
        :placeholder="`${LL.search()}...`"
        :groups="groups"
        :fuse="{ resultLimit: 6, fuseOptions: { threshold: 0.1 } }"
        @update:model-value="onSelect"
      /> </UModal
  ></ClientOnly>
</template>

<script setup lang="ts">
import { typesafeI18n } from "~/locales/i18n-vue";

const emit = defineEmits<{
  toggle: [val?: boolean];
}>();
const keywords = ref("");
const { LL } = typesafeI18n();

const commandPaletteRef = ref();
const { groups, isOpenSearch, openSearch, onSelect } = useSearch();

useEventListener("keyup", (event) => {
  if (event.code === EVENT_CODE_ESC) {
    event.preventDefault();
    keywords.value = "";
    emit("toggle");
  }
});

const { metaSymbol } = useShortcuts();

useEventListener("keydown", (event) => {
  // Windows: Ctrl + K
  // Mac: Cmd + K
  if (event.code === EVENT_CODE_K && (event.ctrlKey || event.metaKey)) {
    event.preventDefault();
    openSearch();
  }
});
</script>
