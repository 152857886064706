<template>
  <ClientOnly>
    <UDropdown
      :items="items"
      :ui="{ item: { disabled: 'cursor-text select-text' } }"
      :popper="{ placement: 'bottom-start' }"
    >
      <UButton
        title="settings"
        aria-label="settings"
        color="white"
        icon="i-heroicons-computer-desktop-solid"
      />

      <template #header="{ item }">
        <div class="text-left">
          <p>{{ item.label }}</p>
        </div>
      </template>

      <template #market="{ item }">
        <span class="truncate">{{ item.label }}</span>
        <UIcon
          :name="item.icon"
          class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 ms-auto"
        />
      </template>

      <template #language="{ item }">
        <div class="flex justify-between w-full">
          <span class="truncate">{{ item.label }}</span>
          <span>{{ item.icon }}</span>
        </div>
      </template>
    </UDropdown>
  </ClientOnly>
</template>

<script setup lang="ts">
import { ELanguage, EMarket, EThemColor } from "../../../constants/enum";
import { useUserStore } from "../../../store/user";
import { typesafeI18n } from "~/locales/i18n-vue";
import { Locales } from "~/locales/i18n-types";
import { loadLocaleAsync } from "~/locales/i18n-util.async";

const route = useRoute();
const userStore = useUserStore();
const cookieLocale = useCookie("locale");
const cookieMarket = useCookie("market");
const { locale, setLocale, LL } = typesafeI18n();
const colorMode = useColorMode();

const handleChangeLanguage = async (language: Locales) => {
  await loadLocaleAsync(language);
  setLocale(language);
  cookieLocale.value = language;
};

const handleChangeMarket = async (market: EMarket) => {
  cookieMarket.value = market;
  userStore.updateMarket(market);
  if (market === EMarket.Global) {
    await handleChangeLanguage(ELanguage.En);
    if (route.path.startsWith("/phong-van-pro")) {
      await navigateTo("/phong-van-pro/full-stack-web-and-mobile-global/java-global");
    }
  } else {
    await handleChangeLanguage(ELanguage.Vi);
    if (route.path.startsWith("/phong-van-pro")) {
      await navigateTo("/phong-van-pro/full-stack-web-and-mobile/java");
    }
  }
  reloadNuxtApp({
    force: true,
  });
};

const handleChangeTheme = (value) => {
  colorMode.preference = value;
  colorMode.value = value;
};

const items = computed(() => [
  [
    {
      slot: "header",
      label: LL.value.language(),
      disabled: true,
    },
    {
      slot: "language",
      label: "Việt Nam",
      iso: "vi",
      icon: "🇻🇳",
      class:
        locale.value === "vi"
          ? "bg-gray-100 border-[1px] border-gray-200 dark:bg-gray-700 dark:border-gray-600"
          : "",
      click: () => {
        handleChangeLanguage("vi");
      },
    },
    {
      slot: "language",
      label: "English",
      iso: "en",
      icon: "🇺🇸",
      class:
        locale.value === "en"
          ? "bg-gray-100 border-[1px] border-gray-200 dark:bg-gray-700 dark:border-gray-600"
          : "",
      click: () => {
        handleChangeLanguage("en");
      },
    },
  ],
  [
    {
      slot: "header",
      label: LL.value.market(),
      disabled: true,
    },
    {
      slot: "market",
      label: "Global",
      icon: "i-heroicons-globe-asia-australia-solid",
      class:
        cookieMarket.value === EMarket.Global
          ? "bg-gray-100 border-[1px] border-gray-200 dark:bg-gray-700 dark:border-gray-600"
          : "",
      click: () => {
        handleChangeMarket(EMarket.Global);
      },
    },
    {
      slot: "market",
      label: "Việt Nam",
      icon: "i-heroicons-map-pin-solid",
      class:
        cookieMarket.value === EMarket.VietNam
          ? "bg-gray-100 border-[1px] border-gray-200 dark:bg-gray-700 dark:border-gray-600"
          : "",
      click: () => {
        handleChangeMarket(EMarket.VietNam);
      },
    },
    ...(userStore.isFpter
      ? [
          {
            slot: "market",
            label: "FPT",
            icon: "i-heroicons-academic-cap-solid",
            class:
              cookieMarket.value === EMarket.Fpt
                ? "bg-gray-100 border-[1px] border-gray-200 dark:bg-gray-700 dark:border-gray-600"
                : "",
            click: () => {
              handleChangeMarket(EMarket.Fpt);
            },
          },
        ]
      : []),
  ],
  [
    {
      slot: "header",
      label: LL.value.color_mode(),
      disabled: true,
    },
    {
      slot: "market",
      label: LL.value.system(),
      icon: "i-heroicons-computer-desktop-solid",
      class:
        colorMode.preference === EThemColor.System
          ? "bg-gray-100 border-[1px] border-gray-200 dark:bg-gray-700 dark:border-gray-600"
          : "",
      click: () => {
        handleChangeTheme(EThemColor.System);
      },
    },
    {
      slot: "market",
      label: LL.value.light(),
      icon: "i-heroicons-sun-20-solid",
      class:
        colorMode.preference === EThemColor.Light
          ? "bg-gray-100 border-[1px] border-gray-200 dark:bg-gray-700 dark:border-gray-600"
          : "",
      click: () => {
        handleChangeTheme(EThemColor.Light);
      },
    },
    {
      slot: "market",
      label: LL.value.dark(),
      icon: "i-heroicons-moon-20-solid",
      class:
        colorMode.preference === EThemColor.Dark
          ? "bg-gray-100 border-[1px] border-gray-200 dark:bg-gray-700 dark:border-gray-600"
          : "",
      click: () => {
        handleChangeTheme(EThemColor.Dark);
      },
    },
  ],
]);
</script>
