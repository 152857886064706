<template>
  <UDropdown :items="items" mode="hover" :popper="{ placement: 'bottom-start' }">
    <UButton
      variant="soft"
      :label="userStore.isFptMarket ? 'Học tập' : LL.course()"
      trailing-icon="i-heroicons-chevron-down-20-solid"
    />
  </UDropdown>
</template>

<script setup lang="ts">
import { useUserStore } from "../../../store/user";
import { typesafeI18n } from "~/locales/i18n-vue";

const { LL } = typesafeI18n();

const userStore = useUserStore();

const items = computed(() => [
  [
    ...(userStore.isVietnamMarket
      ? [
          {
            id: "javascript",
            label: LL.value.course_name({
              courseName: "Javascript",
            }),
            icon: "i-simple-icons-javascript",
            to: "/khoa-hoc/javascript",
          },
          {
            id: "java",
            label: LL.value.course_name({
              courseName: "Java",
            }),
            icon: "i-simple-icons-coffeescript",
            to: "/khoa-hoc/java",
          },

          {
            id: "angular",
            label: LL.value.course_name({
              courseName: "Angular",
            }),
            icon: "i-simple-icons-angular",
            to: "/khoa-hoc/angular",
          },
          {
            id: "spring-boot",
            label: LL.value.course_name({
              courseName: "Spring boot",
            }),
            icon: "i-simple-icons-spring",
            to: "/khoa-hoc/spring-boot",
          },
          {
            id: "python",
            label: LL.value.course_name({
              courseName: "Python",
            }),
            icon: "i-simple-icons-python",
            to: "/khoa-hoc/python",
          },
          {
            id: "reactjs",
            label: LL.value.course_name({
              courseName: "Reactjs",
            }),
            icon: "i-simple-icons-react",
            to: "/khoa-hoc/reactjs",
          },
          {
            id: "aws",
            label: LL.value.course_name({
              courseName: "AWS",
            }),
            icon: "i-simple-icons-amazon",
            to: "/khoa-hoc/aws",
          },
          {
            id: "go",
            label: LL.value.course_name({
              courseName: "Go",
            }),
            icon: "i-simple-icons-go",
            to: "/khoa-hoc/go",
          },
          {
            id: "c",
            label: LL.value.course_name({
              courseName: "C",
            }),
            icon: "i-simple-icons-c",
            to: "/khoa-hoc/c",
          },
          {
            id: "react-native",
            label: LL.value.course_name({
              courseName: "React Native",
            }),
            icon: "i-simple-icons-react",
            to: "/khoa-hoc/react-native",
          },
          {
            id: "docker",
            label: LL.value.course_name({
              courseName: "Docker",
            }),
            icon: "i-simple-icons-docker",
            to: "/khoa-hoc/docker",
          },
          {
            id: "c++",
            label: LL.value.course_name({
              courseName: "C++",
            }),
            icon: "i-simple-icons-cplusplus",
            to: "/khoa-hoc/cpp",
          },
          {
            id: "c++",
            label: LL.value.course_name({
              courseName: "Blockchain",
            }),
            icon: "i-simple-icons-bitcoin",
            to: "/khoa-hoc/blockchain",
          },
          {
            id: "c++",
            label: LL.value.course_name({
              courseName: "Ruby",
            }),
            icon: "i-simple-icons-ruby",
            to: "/khoa-hoc/ruby",
          },
        ]
      : []),
    ...(userStore.isGlobalMarket
      ? [
          {
            id: "algorithms",
            label: "Algorithms",
            icon: "i-simple-icons-thealgorithms",
            to: "/khoa-hoc/algorithms",
          },
        ]
      : []),
    ...(userStore.isFptMarket
      ? [
          {
            id: "se",
            label: "SE",
            icon: "i-simple-icons-flat",
            to: "/khoa-hoc/se",
          },
        ]
      : []),
    {
      id: "all",
      label: LL.value.all(),
      icon: "i-heroicons-ellipsis-horizontal-20-solid",
      to: "/khoa-hoc",
    },
  ],
]);
</script>
