import * as Sentry from '@sentry/browser'
import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app'
import { ComponentPublicInstance } from 'vue'

export default defineNuxtPlugin(({ vueApp }) => {
  const config = useRuntimeConfig()
  const isDev = config.public.env === 'development'
  if (isDev) return

  Sentry.init({
    dsn: 'https://a6557f1d1f7414aba2d83b1d59f3494e@o4506869605072896.ingest.us.sentry.io/4506870497411072',
    tracePropagationTargets: ['https://api.kungfutech.edu.vn/api/'],
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
    // release: '', // TODO: Expose current version of app to html on server side
    environment: config.public.env,
    autoSessionTracking: true,
    ignoreErrors: [
      'fb_xd_fragment',
      // Ad blocker
      'Failed to load resource: net::ERR_FAILED',
      "TypeError: Cannot read properties of undefined (reading 'apply')",
      "TypeError: Cannot read properties of null (reading 'Error')",
      'SyntaxError: redeclaration of const t',
      "Identifier 't' has already been declared",
      "SyntaxError: Can't create duplicate variable: 't'",
      "Cannot read property 'Error' of null",
      "adsbygoogle.push() error: All 'ins' elements in the DOM with class=adsbygoogle already have ads in them.",
      'zaloJSV2 is not defined',
      "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
      "Cannot read properties of null (reading 'namespaceURI')",
      'g.replaceAll',
      'NS_ERROR_NOT_INITIALIZED',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /facebook\.com/,
      /connect\.facebook\.net\/en_us\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
  })

  vueApp.config.errorHandler = (
    error: unknown,
    vm: ComponentPublicInstance | null,
    info: string,
  ) => {
    const componentName =
      vm?.$options?.name || vm?.$options?._componentTag || '<Anonymous>'
    const metadata = {
      componentName,
      info,
      props: vm?.$props,
    }

    // Capture exception in the next event loop, to make sure that all breadcrumbs are recorded in time.
    setTimeout(() => {
      Sentry.withScope((scope) => {
        scope.setContext('vue', metadata)
        // Adds exception mechanism data to a given event
        scope.addEventProcessor((event) => {
          const firstException = event.exception?.values?.[0]
          if (firstException) {
            const mechanism = { type: 'generic', handled: false }
            const currentMechanism = firstException.mechanism
            firstException.mechanism = { ...mechanism, ...currentMechanism }
          }
          return event
        })
        Sentry.captureException(error)
      })
    })
  }
})
