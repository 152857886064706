import validate from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/nuxt/dist/pages/runtime/validate.js";
import setup_45global from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/middleware/setup.global.ts";
import manifest_45route_45rule from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  setup_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-only": () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/middleware/admin-only.ts"),
  "guest-only": () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/middleware/guest-only.ts"),
  "member-only": () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/middleware/member-only.ts")
}