<script lang="ts" setup>
import { typesafeI18n } from "./locales/i18n-vue";
import { useSetupApp } from "./utils/app";

useSetupApp();
const theme = useState("theme.current");
const { locale } = typesafeI18n();

useHead({
  meta: [{ name: "viewport", content: "width=device-width, initial-scale=1" }],
  link: [{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" }],
});
</script>

<template>
  <Html :class="`${theme === 'dark' ? 'dark' : ''}`" :lang="locale">
    <Body
      class="antialiased font-sans text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-900"
    >
      <NuxtLoadingIndicator :height="2" :duration="3000" :throttle="400" />
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </Body>
    <ClientOnly> <UNotifications icon="i-heroicons-check-circle" /></ClientOnly>
  </Html>
</template>

<style>
body {
  margin: 0;
}
</style>
