<template>
  <nuxt-link
    to="/"
    class="flex items-end gap-1 font-bold text-xl text-gray-900 dark:text-white"
  >
    <svg
      width="1000"
      height="1000"
      viewBox="0 0 1000 1000"
      class="w-7 h-7 text-primary-500 dark:text-primary-400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3623_16107"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="67"
        y="15"
        width="867"
        height="969"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M699.936 608.52C699.936 612.807 697.649 616.768 693.936 618.912L505.996 727.419C502.283 729.563 499.996 733.524 499.996 737.811L499.996 972.532C499.996 979.158 505.386 984.605 511.965 983.813C525.165 982.226 538.119 977.999 550.013 971.132L883.025 778.867C913.965 761.004 933.025 727.991 933.025 692.265L933.025 307.735C933.025 272.008 913.965 238.996 883.025 221.132L550.013 28.8673C519.073 11.0041 480.953 11.0041 450.013 28.8673L117 221.132C86.0599 238.996 67 272.008 67 307.735L67.0001 692.265C67.0001 727.991 86.06 761.004 117 778.867L282.056 874.162C290.056 878.781 300.056 873.008 300.056 863.77L300.056 391.505C300.056 387.218 302.343 383.257 306.056 381.113L493.996 272.606C497.709 270.462 502.283 270.462 505.996 272.606L693.936 381.113C697.649 383.257 699.936 387.218 699.936 391.505L699.936 608.52Z"
          fill="#3A36FF"
        />
      </mask>
      <g mask="url(#mask0_3623_16107)">
        <rect
          width="624.167"
          height="233.985"
          transform="matrix(0.866025 0.499999 0.5 -0.866025 -196.465 599.943)"
          fill="#E5E7EB"
        />
        <rect
          x="666.597"
          y="1098.23"
          width="372.393"
          height="234.436"
          transform="rotate(-150 666.597 1098.23)"
          fill="#5EEAD4"
        />
        <rect
          width="692.999"
          height="203.408"
          transform="matrix(0.866025 0.499999 0.499999 -0.866025 -196.45 331.438)"
          fill="#D1D5DB"
        />
        <rect
          x="931.154"
          y="982.497"
          width="609.054"
          height="203.407"
          transform="rotate(-150 931.154 982.497)"
          fill="#2DD4BF"
        />
        <rect
          width="559.886"
          height="203.408"
          transform="matrix(0.866025 0.499999 0.499999 -0.866025 29.0361 228.532)"
          fill="#9CA3AF"
        />
        <rect
          width="559.886"
          height="203.408"
          transform="matrix(0.866025 0.499999 0.499999 -0.866025 469 482.156)"
          fill="#14B8A6"
        />
        <rect
          width="1252.64"
          height="310.623"
          transform="matrix(0.866025 0.499999 0.499999 -0.866025 103.357 40.0879)"
          fill="url(#paint0_linear_3623_16107)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3623_16107"
          x1="952.149"
          y1="7.11518"
          x2="334.588"
          y2="226.762"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0D9488" />
          <stop offset="1" stop-color="#14B8A6" />
        </linearGradient>
      </defs>
    </svg>
    <p class="flex gap-[1px] relative">
      <span class="font-black">KUNGFU</span>
      <span class="text-primary-500 dark:text-primary-400 font-black">
        TECH </span
      ><UBadge
        color="teal"
        variant="soft"
        class="px-[5px] py-[1px] absolute top-[-9px] right-[-20px] z-10"
      >
        {{ userStore.getMarket }}
      </UBadge>
    </p>
  </nuxt-link>
</template>

<script lang="ts" setup>
import { useUserStore } from '../store/user'

const userStore = useUserStore()
</script>
