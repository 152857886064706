<template>
  <UButton :label="LL.login()" @click="handleOpenAuthModal" />
</template>

<script lang="ts" setup>
import { useAuthStore } from "~/features/auth/auth.store";
import { typesafeI18n } from "~/locales/i18n-vue";

const authStore = useAuthStore();
const { LL } = typesafeI18n();

const handleOpenAuthModal = () => {
  authStore.setOpenAuthModal(true);
};
</script>
