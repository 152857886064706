import { defineStore } from 'pinia'
import { TAuthStore } from './auth.types'

export const useAuthStore = defineStore('auth', {
  state: (): TAuthStore => ({
    openAuthModal: false,
  }),
  actions: {
    setOpenAuthModal(isOpenAuthModal: boolean) {
      this.openAuthModal = isOpenAuthModal
    },
  },
  getters: {
    getOpenAuthModal(): boolean {
      return this.openAuthModal
    },
  },
})
