import { typesafeI18n } from '~/locales/i18n-vue'
import { useUserStore } from '~/store'

export const useSearch = () => {
  const { LL } = typesafeI18n()
  const router = useRouter()
  const userStore = useUserStore()
  const isOpenSearch = ref(false)

  const openSearch = () => {
    isOpenSearch.value = true
  }

  const links = computed(() => [
    {
      id: 'home',
      label: LL.value.home(),
      to: '/',
      icon: 'i-heroicons-home',
    },
    {
      id: 'course',
      label: LL.value.course(),
      to: '/khoa-hoc',
      icon: 'i-heroicons-book-open',
    },
    {
      id: 'interview_questions',
      label: LL.value.interview_questions(),
      to: '/phong-van-pro/full-stack-web-and-mobile/java',
      icon: 'i-heroicons-square-3-stack-3d',
    },
    {
      id: 'interview_questions_global',
      label: `${LL.value.interview_questions()} global`,
      to: '/phong-van-pro/full-stack-web-and-mobile-global/java-global',
      icon: 'i-heroicons-academic-cap',
    },
    {
      id: 'post',
      label: 'Post',
      to: '/posts',
      icon: 'i-heroicons-computer-desktop',
    },
    {
      id: 'upgrade_account',
      label: LL.value.upgrade_account(),
      to: '/nang-cap-tai-khoan',
      icon: 'i-heroicons-rocket-launch',
    },
  ])

  const actions = computed(() => [
    {
      id: 'create_articles',
      label: LL.value.create_articles(),
      icon: 'i-heroicons-document-plus',
      to: '/posts/create',
    },
    {
      id: 'my_profile',
      label: LL.value.my_profile(),
      icon: 'i-heroicons-user',
      to: userStore.isLogged ? `/users/${userStore.username}` : '/dang-ky',
    },
  ])

  const course = computed(() => [
    {
      id: 'javascript',
      label: LL.value.course_name({
        courseName: 'Javascript',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/javascript',
    },
    {
      id: 'java',
      label: LL.value.course_name({
        courseName: 'Java',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/java',
    },
    {
      id: 'python',
      label: LL.value.course_name({
        courseName: 'Python',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/python',
    },
    {
      id: 'reactjs',
      label: LL.value.course_name({
        courseName: 'Reactjs',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/reactjs',
    },
    {
      id: 'angular',
      label: LL.value.course_name({
        courseName: 'Angular',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/angular',
    },
    {
      id: 'spring-boot',
      label: LL.value.course_name({
        courseName: 'Spring boot',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/spring-boot',
    },
    {
      id: 'aws',
      label: LL.value.course_name({
        courseName: 'AWS',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/aws',
    },
    {
      id: 'go',
      label: LL.value.course_name({
        courseName: 'Go',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/go',
    },
    {
      id: 'c',
      label: LL.value.course_name({
        courseName: 'C',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/c',
    },
    {
      id: 'react-native',
      label: LL.value.course_name({
        courseName: 'React Native',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/react-native',
    },
    {
      id: 'docker',
      label: LL.value.course_name({
        courseName: 'Docker',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/docker',
    },
    {
      id: 'c++',
      label: LL.value.course_name({
        courseName: 'C++',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/cpp',
    },
    {
      id: 'blockchain',
      label: LL.value.course_name({
        courseName: 'Blockchain',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/blockchain',
    },
    {
      id: 'ruby',
      label: LL.value.course_name({
        courseName: 'Ruby',
      }),
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/ruby',
    },
    {
      id: 'algorithms',
      label: 'Algorithms',
      icon: 'i-heroicons-document-text',
      to: '/khoa-hoc/algorithms',
    },
  ])

  const groups = computed(() => [
    {
      key: 'links',
      commands: links.value,
    },
    {
      key: 'actions',
      commands: actions.value,
    },
    {
      key: 'course',
      label: LL.value.course(),
      commands: course.value,
    },
  ])

  const onSelect = (option) => {
    if (option.click) {
      option.click()
      router.push(option.to)
    } else if (option.to) {
      router.push(option.to)
      isOpenSearch.value = false
    } else if (option.href) {
      window.open(option.href, '_blank')
      router.push(option.to)
    }
  }
  return {
    groups,
    isOpenSearch,
    openSearch,
    onSelect,
  }
}
