export default defineAppConfig({
  name: 'KungFuTech',
  description:
    'KungFuTech là nơi giúp bạn học lập trình online, chia sẻ kiến thức lập trình bao gồm tài liệu học lập trình, các khóa học lập trình',
  url: 'https://kungfutech.edu.vn',
  author: {
    name: 'thaycacac',
    link: 'https://github.com/thaycacac',
  },
  ui: {
    primary: 'teal',
    card: {
      ring: 'ring-gray-200 dark:ring-gray-700',
      body: {
        padding: 'px-3 py-3 sm:p-3',
      },
    },
    notifications: {
      position: 'bottom-0 end-0',
    },
  },
  organization: {
    name: 'Techmely',
    url: 'https://techmely.com',
    members: [
      {
        name: 'Trần Quang Nhật',
        nickname: 'harrytran998',
        jobTitle: 'Founder',
        avatarUrl: '',
        relatedLinks: [],
      },
      {
        name: 'Phạm Ngọc Hoà',
        nickname: 'thaycacac',
        jobTitle: 'Founder',
        avatarUrl: '',
        relatedLinks: [],
      },
      {
        name: 'Đỗ Quang Hiệp',
        nickname: 'flame',
        jobTitle: 'Co-Founder',
        avatarUrl: '',
        relatedLinks: [],
      },
    ],
  },
})
