import { defineStore } from 'pinia'
import { EMarket, ERole } from '../constants/enum'
import { TPostRegisterPayload, useAuthStore } from 'features/auth'
import { postLogin, postRegister } from 'features/auth/auth.service'
import {
  TUserLoginWithProvider,
  TPostChangePasswordPayload,
  TPostUpdateProfilePayload,
  TUser,
  TUserLogin,
  changePassword,
  getMe,
  updateAvatar,
  updateProfile,
} from 'features/user'

export const useUserStore = defineStore('user', {
  state: (): TUser => ({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    phoneNumber: '',
    imageUrl: '',
    dob: '',
    provider: '',
    providerId: '',
    roles: [],
    market: EMarket.Global,
    point: 0,
  }),
  actions: {
    loginWithProvider({ jwt }: TUserLoginWithProvider) {
      const { $cookieService } = useNuxtApp()
      $cookieService.setToken(jwt)
      setTimeout(async () => {
        const { data } = await getMe()
        this.$state = data.value?.data as TUser
      }, 500)
    },
    async login({ payload, callbackSuccess, callbackError }: TUserLogin) {
      const { $cookieService } = useNuxtApp()
      const { data, error } = await postLogin(payload)
      const response = data.value
      if (response) {
        this.id = response?.user.id ?? ''
        this.firstName = response?.user.firstName ?? ''
        this.lastName = response?.user.lastName ?? ''
        this.email = response?.user.email ?? ''
        this.username = response?.user.username ?? ''
        this.phoneNumber = response?.user.phoneNumber ?? ''
        this.imageUrl = response?.user.imageUrl ?? ''
        this.phoneNumber = response?.user.phoneNumber ?? ''
        this.dob = response?.user.dob ?? ''
        this.provider = response?.user.provider ?? ''
        this.providerId = response?.user.providerId ?? ''
        this.roles = response?.user.roles ?? []
        this.point = response?.user.point ?? 0
        if (response) {
          $cookieService.setToken(response.token)
          $cookieService.set(
            `refresh_token_${$cookieService.env}`,
            response.refresh_token,
          )
        }
        if (response?.user.email.toLocaleLowerCase().includes('fpt')) {
          this.market = EMarket.Fpt
        }
        callbackSuccess()
      } else {
        callbackError(error?.value?.data?.message)
      }
    },
    async changePassword(
      payload: TPostChangePasswordPayload,
      titleNotify: string,
    ) {
      const authStore = useAuthStore()
      const toast = useToast()
      const {
        data: { value },
      } = await changePassword(payload)

      if (value) {
        toast.add({ title: titleNotify })
        const { $cookieService } = useNuxtApp()
        $cookieService.clearToken()
        $cookieService.set(`refresh_token_${$cookieService.env}`, '')
        this.$reset()
        authStore.setOpenAuthModal(true)
      }
    },
    async updateProfile(
      payload: TPostUpdateProfilePayload,
      titleNotify: string,
    ) {
      const toast = useToast()
      const {
        data: { value },
      } = await updateProfile(payload)

      if (value) {
        toast.add({ title: titleNotify })
      }
    },
    async updateAvatar(payload: FormData, titleNotify: string) {
      const toast = useToast()
      const {
        data: { value },
      } = await updateAvatar(payload)

      if (value) {
        toast.add({ title: titleNotify })
        this.imageUrl = value?.data?.imageUrl ?? ''
      }
    },
    async register(payload: TPostRegisterPayload, titleNotify: string) {
      const authStore = useAuthStore()
      const toast = useToast()
      const { data } = await postRegister(payload)
      if (data.value) {
        toast.add({ title: titleNotify })
        authStore.setOpenAuthModal(true)
      }
    },
    signOut() {
      const { $cookieService } = useNuxtApp()
      $cookieService.clearToken()
      $cookieService.set(`refresh_token_${$cookieService.env}`, '')
      this.$reset()
      navigateTo('/')
    },
    checkIsLogged(title: string, func: VoidFunction) {
      const authStore = useAuthStore()
      const toast = useToast()
      if (!this.id) {
        toast.add({ title, color: 'red' })
        authStore.setOpenAuthModal(true)
        return
      }
      func()
    },
    updateMarket(market: EMarket) {
      this.market = market
    },
  },
  getters: {
    isLogged(): boolean {
      return !!this.id
    },
    role(): string {
      if (
        this.roles.includes(ERole.SuperAdmin) ||
        this.roles.includes(ERole.Admin)
      ) {
        return 'Admin'
      } else if (this.roles.includes(ERole.Diamond)) {
        return 'Diamond'
      } else if (this.roles.includes(ERole.Gold)) {
        return 'Gold'
      } else if (this.roles.includes(ERole.Silver)) {
        return 'Silver'
      }
      return 'Member'
    },
    isShowUpgradeAccount(): boolean {
      return this.roles.length === 1
    },
    getMarket(): EMarket {
      return this.market
    },
    isGlobalMarket(): boolean {
      return this.market === EMarket.Global
    },
    isFptMarket(): boolean {
      return this.market === EMarket.Fpt
    },
    isVietnamMarket(): boolean {
      return this.market === EMarket.VietNam
    },
    isFpter(): boolean {
      return (
        this.email.includes('fpt') ||
        this.roles.includes(ERole.Admin) ||
        this.roles.includes(ERole.SuperAdmin)
      )
    },
    isUpgraded(): boolean {
      return (
        this.roles.includes(ERole.Silver) ||
        this.roles.includes(ERole.Diamond) ||
        this.roles.includes(ERole.Admin) ||
        this.roles.includes(ERole.SuperAdmin) ||
        this.roles.includes(ERole.Gold)
      )
    },
    isAdmin(): boolean {
      return (
        this.roles.includes(ERole.Admin) ||
        this.roles.includes(ERole.SuperAdmin)
      )
    },
    getAvatarUrl(): string {
      return this.imageUrl
    },
    getFullname(): string {
      return `${this.firstName} ${this.lastName}`
    },
  },
})
