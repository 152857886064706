import type {
  TPostLoginPayload,
  TPostLoginResponse,
  TPostRegisterPayload,
  TPostRegisterResponse,
} from './auth.types'

export async function postLogin(payload: TPostLoginPayload) {
  const data = await makeRequest<TPostLoginResponse>('/api/auth/login', {
    method: 'POST',
    body: payload,
  })
  return data
}

export async function postRegister(payload: TPostRegisterPayload) {
  const data = await makeRequest<TPostRegisterResponse>('/api/auth/register', {
    method: 'POST',
    body: payload,
  })
  return data
}
