import { useCookie } from 'nuxt/app'
import { EMarket } from '../constants/enum'
import { TUser, getMe } from 'features/user'
import { useUserStore } from '~/store'

export default defineNuxtRouteMiddleware(async () => {
  // const requestEvent = useRequestEvent()
  // const acceptLanguage = requestEvent?.req?.headers['accept-language']
  // const marketDetect = acceptLanguage?.includes('vi')
  //   ? EMarket.VietNam
  //   : EMarket.Global
  const userStore = useUserStore()
  const { $cookieService } = useNuxtApp()
  const tokenServer = useCookie($cookieService.tokenName)
  // @ts-ignore
  if (tokenServer.value) {
    const { data } = await getMe()
    userStore.$state = data.value?.data as unknown as TUser
  }
  if (import.meta.server) {
    const market = useCookie('market').value || EMarket.VietNam
    if (market) userStore.updateMarket(market as EMarket)
  }
  if (import.meta.client) {
    const market = useCookie('market').value || EMarket.VietNam
    if (market) userStore.updateMarket(market as EMarket)
  }
})
