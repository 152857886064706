<script lang="ts" setup>
import { typesafeI18n } from "~/locales/i18n-vue";

const { LL } = typesafeI18n();
const props = defineProps({
  code: {
    type: Number,
    default: 404,
  },
  wrap: {
    type: Boolean,
    default: false,
  },
});

const errorsMap: {
  [key: string]: string;
} = {
  "400": "Bad Request",
  "401": "Unauthorized",
  "403": "Forbidden",
  "404": "Not Found",
};
const error = computed(() => {
  const { code } = props;
  return {
    code,
    message: errorsMap[code.toString()] || "Unknown Error",
  };
});
</script>

<template>
  <UContainer class="grid grid-cols-12 items-center justify-center h-[calc(100vh-360px)]">
    <div class="col-span-12 md:col-span-6 lg:col-span-4">
      <ImagePreset
        src="/images/common/error-page.png"
        class="pl-2.5"
        width="300"
        :alt="`Trang web đã xảy ra lỗi ${error.code}`"
      />
    </div>
    <div class="col-span-12 md:col-span-6 lg:col-span-8 text-center">
      <h1 class="mb-6 leading-3">
        <span class="font-bold text-8xl block">{{ error.code }}</span>
        <span class="block italic">{{ error.message }}</span>
        <template v-if="error.code === 404">
          <p class="mt-4 text-3xl font-bold">{{ LL.oh_it_seems_you_are_lost() }}</p>
          <p class="mt-4 text-xl italic">
            {{ LL.we_cant_find_the_address_you_are_trying_to_visit() }}
          </p>
        </template>
      </h1>
      <UButton color="black" size="lg" :text="LL.back_to_homepage()" to="/" />
    </div>
  </UContainer>
</template>
